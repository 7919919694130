<script setup>
import {onBeforeMount, reactive, ref} from "vue"
import request from "../request"
import {useStore} from "vuex"

const store = useStore()

const form = ref(null)
const formData = reactive({
  name: null
})

const loading = ref(false)
const saving = ref(false)

const getOrganizationInfo = () => {
  loading.value = true

  request.get(process.env.VUE_APP_BASE_URL +  `/organizations/${store.getters.organizationId}`)
      .then(data => {
        loading.value = false
        formData.name = data.name
      })
      .catch(error => {
        loading.value = false
      })
}

onBeforeMount(() => {
  if (store.getters.isOwner) {
    getOrganizationInfo()
  }
})

const save = () => {
  saving.value = true

  request.put(process.env.VUE_APP_BASE_URL + `/organizations/${store.getters.organizationId}`, formData)
      .then(response => {
        saving.value = false
      })
      .catch(error => {
        saving.value = false
      })
}
</script>

<template>
  <var-skeleton v-if="store.getters.isOwner" :rows="7" :loading="loading">
    <var-card
        title="Organization"
        class="mb-4"
    >
      <template #extra>
        <var-form ref="form" :disabled="saving">
          <var-input
              placeholder="Name"
              v-model="formData.name"
              class="mb-4"
          />
          <var-row justify="flex-end">
            <var-col :span="8" class="text-end">
              <var-button
                  type="primary"
                  :loading="saving"
                  @click="save"
              >
                Save
              </var-button>
            </var-col>
          </var-row>
        </var-form>
      </template>
    </var-card>
  </var-skeleton>
</template>

<style scoped>

</style>